<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div class="row">
      <div class="col-6">
        <base-input
          :label="`${$t('SALES_ORDERS.EVENT_DATE')}`"
          :placeholder="$t('SALES_ORDERS.EVENT_DATE')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              minDate: 'today',
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="event_date"
            @on-change="() => {}"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="col-6">
        <base-input
          :label="`${$t('SALES_ORDERS.EVENT_TIME')}`"
          :placeholder="$t('SALES_ORDERS.EVENT_TIME')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              minDate: 'today',
              locale: $flatPickrLocale(),
              allowInput: true,
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
            }"
            class="form-control datepicker"
            v-model="event_time"
            @on-change="() => {}"
          >
          </flat-picker>
        </base-input>
      </div>
    </div>

    <h2 class="mt-5">{{ $t("SALES_ORDERS.DELIVERY_LOCATION") }}</h2>
    <html-editor v-model="delivery_location" @change="onFormChanged()">
    </html-editor>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="addSalesOrder"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("ESTABLISHMENTS.ADD_SALES_ORDER") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import moment from "moment";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import flatPicker from "vue-flatpickr-component";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultSalesOrder from "../../../SalesModule/SalesOrderManagement/defaultSalesOrder";
import {
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_UNIVERSITY,
} from "../../../../../constants/establishments";

export default {
  name: "estbalishment-view-sales-orders-add-sales-order-modal",

  components: { flatPicker, HtmlEditor },

  mixins: [formMixin],

  props: ["establishment", "showModal"],

  data() {
    return {
      loading: false,
      event_date: null,
      event_time: null,
      delivery_location: null,
    };
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  created() {},

  methods: {
    async addSalesOrder() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        this.establishment.organization.id
      );

      this.loading = true;
      const salesOrderData = cloneDeep(defaultSalesOrder);
      delete salesOrderData.salesInvoice;
      salesOrderData.expiration_time = moment(
        salesOrderData.expiration_time
      ).toISOString();

      salesOrderData.has_no_taxes = ![
        ESTABLISHMENT_TYPE_UNIVERSITY,
        ESTABLISHMENT_TYPE_COLLEGE,
      ].includes(this.establishment.establishment_type);
      salesOrderData.recipient = this.establishment.customer;
      salesOrderData.organization = this.establishment.organization;
      salesOrderData.allowedLocations = this.establishment.allowedLocations;
      salesOrderData.billing_entity_type =
        this.establishment.billing_entity_type;
      salesOrderData.billing_company_name =
        this.establishment.billing_company_name;
      salesOrderData.billing_firstname = this.establishment.billing_firstname;
      salesOrderData.billing_lastname = this.establishment.billing_lastname;
      salesOrderData.billing_country = this.establishment.billing_country;
      salesOrderData.billing_state = this.establishment.billing_state;
      salesOrderData.billing_city = this.establishment.billing_city;
      salesOrderData.billing_zipcode = this.establishment.billing_zipcode;
      salesOrderData.billing_address = this.establishment.billing_address;
      salesOrderData.billing_email = this.establishment.billing_email;
      salesOrderData.terms_conditions =
        this.organizationConfig.DEFAULT_SALES_ORDER_TERMS;
      salesOrderData.description =
        this.organizationConfig.DEFAULT_SALES_ORDER_DESCRIPTION;
      salesOrderData.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_SALES_PAYMENT_TERMS,
      };

      delete salesOrderData.cafeteria;
      salesOrderData.establishment = {
        type: "establishments",
        id: this.establishment.id,
      };
      salesOrderData.serviceCenter = {
        type: "service-centers",
        id: this.establishment.serviceCenter.id,
      };
      if (this.establishment.has_warehouse) {
        salesOrderData.sourceWarehouse = {
          type: "warehouses",
          id: this.establishment.warehouse.id,
        };
      } else {
        delete salesOrderData.sourceWarehouse;
      }

      salesOrderData.event_date = moment(
        this.event_date + " " + this.event_time
      ).toISOString();
      salesOrderData.delivery_location = this.delivery_location;

      try {
        await this.$store.dispatch("salesOrders/add", salesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ADDED"),
        });
        const salesOrder = await this.$store.getters["salesOrders/salesOrder"];
        this.$router.push({
          name: "View SalesOrder",
          params: { id: salesOrder.id },
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
      swal.close();
    },

    closeModal() {
      this.$emit("onCloseModal");
    },
  },

  mounted() {},

  watch: {},
};
</script>
