var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":`${_vm.$t('SALES_ORDERS.EVENT_DATE')}`,"placeholder":_vm.$t('SALES_ORDERS.EVENT_DATE')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {}},model:{value:(_vm.event_date),callback:function ($$v) {_vm.event_date=$$v},expression:"event_date"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":`${_vm.$t('SALES_ORDERS.EVENT_TIME')}`,"placeholder":_vm.$t('SALES_ORDERS.EVENT_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
            allowInput: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
          }},on:{"on-change":() => {}},model:{value:(_vm.event_time),callback:function ($$v) {_vm.event_time=$$v},expression:"event_time"}})],1)],1)]),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("SALES_ORDERS.DELIVERY_LOCATION")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.delivery_location),callback:function ($$v) {_vm.delivery_location=$$v},expression:"delivery_location"}}),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.addSalesOrder}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("ESTABLISHMENTS.ADD_SALES_ORDER"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }