<template>
  <div class="container-fluid">
    <div class="row mb-5" v-if="establishment.company_name">
      <h1>{{ `${establishment.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("USERS.ACTIVE") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!establishment.active" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.HAS_FOLDER") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!establishment.has_folder" />
          </dd>
        </dl>
        <dl class="row" v-if="establishment.has_warehouse">
          <dt class="col-sm-4">{{ $t("COMMON.HAS_WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!establishment.has_warehouse" />
          </dd>
        </dl>
        <dl class="row" v-if="establishment.establishment_type">
          <dt class="col-sm-4">{{ $t("ESTABLISHMENTS.SCHOOL_LEVEL") }}</dt>
          <dd class="col-sm-8">
            {{
              $t(
                "ESTABLISHMENTS.ESTABLISHMENT_TYPE_" +
                  establishment.establishment_type
              )
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd class="col-sm-8">
            <tags
              :tags="establishment.tags"
              @tagsUpdated="establishmentUpdated"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CUSTOMER_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.customer_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FISCAL_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.fiscal_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            <phone-number
              :phoneNumber="establishment.phone"
              :extension="establishment.phone_extension"
              :type="establishment.phone_type"
            />
          </dd>
        </dl>
        <dl class="row" v-if="establishment?.other_phones?.length">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span
              v-for="(line, index) in establishment.other_phones"
              :key="index"
            >
              <phone-number
                :phoneNumber="line.phoneNumber"
                :extension="line.extension"
                :type="line.type"
              />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ $stateName(establishment.state) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="establishment.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="establishment?.allowedLocations?.length">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="establishment.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.CUSTOMER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="establishment.customer" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <service-center :serviceCenter="establishment.serviceCenter" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="establishment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(establishment.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(establishment.updated_at) }}
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("ESTABLISHMENTS.ADMINISTRATION") }}</h2>
        </div>

        <dl class="row" v-if="establishment.coordinator">
          <dt class="col-sm-4">{{ $t("ESTABLISHMENTS.COORDINATOR") }}</dt>
          <dd class="col-sm-8">
            <dl class="row mb-0">
              <dt class="col-sm-2">{{ $t("COMMON.NAME") }}</dt>
              <dd class="col-sm-10">
                {{
                  `${establishment.coordinator.firstname} ${establishment.coordinator.lastname}`
                }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-2">{{ $t("COMMON.EMAIL") }}</dt>
              <dd class="col-sm-10">
                <a :href="`mailto:${establishment.coordinator.email}`">
                  {{ establishment.coordinator.email }}
                </a>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-2">{{ $t("COMMON.PHONE") }}</dt>
              <dd class="col-sm-10">
                <span>
                  <phone-number
                    :phoneNumber="establishment.coordinator.phone"
                    :extension="establishment.coordinator.phone_extension"
                    :type="establishment.coordinator.phone_type"
                  />
                  <br />
                </span>
                <span
                  v-for="(line, index) in establishment.coordinator
                    .other_phones"
                  :key="index"
                >
                  <phone-number
                    :phoneNumber="line.phoneNumber"
                    :extension="line.extension"
                    :type="line.type"
                  />
                  <br />
                </span>
              </dd>
            </dl>
          </dd>
        </dl>

        <dl class="row" v-if="establishment.managers.length > 0">
          <dt class="col-sm-4">{{ $t("ESTABLISHMENTS.MANAGERS") }}</dt>
          <dd class="col-sm-8">
            <template v-for="manager of establishment.managers">
              <dl class="row mb-0" :key="manager.id + 100">
                <dt class="col-sm-2">{{ $t("COMMON.NAME") }}</dt>
                <dd class="col-sm-10">
                  {{ `${manager.firstname} ${manager.lastname}` }}
                </dd>
              </dl>
              <dl class="row mb-0" :key="manager.id + 200">
                <dt class="col-sm-2">{{ $t("COMMON.EMAIL") }}</dt>
                <dd class="col-sm-10">
                  <a :href="`mailto:${manager.email}`">
                    {{ manager.email }}
                  </a>
                </dd>
              </dl>
              <dl class="row mb-0" :key="manager.id + 300">
                <dt class="col-sm-2">{{ $t("COMMON.PHONE") }}</dt>
                <dd class="col-sm-10">
                  <span>
                    <phone-number
                      :phoneNumber="manager.phone"
                      :extension="manager.phone_extension"
                      :type="manager.phone_type"
                    />
                    <br />
                  </span>
                  <span
                    v-for="(line, index) in manager.other_phones"
                    :key="index"
                  >
                    <phone-number
                      :phoneNumber="line.phoneNumber"
                      :extension="line.extension"
                      :type="line.type"
                    />
                    <br />
                  </span>
                </dd>
              </dl>
            </template>
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view
          :billingInformations="establishment"
          :type="establishment.billing_entity_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import IconCheck from "@/components/IconCheck.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import ObjectLink from "@/components/ObjectLink.vue";
import {
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_SECONDARY,
  ESTABLISHMENT_TYPE_UNIVERSITY,
} from "@/constants/establishments";

export default {
  name: "establishment-view-global",

  components: {
    IconCheck,
    Tags,
    BillingInformationsView,
    ServiceCenter,
    ObjectLink,
  },

  props: ["establishment"],

  data() {
    return {
      ESTABLISHMENT_TYPE_ELEMENTARY: ESTABLISHMENT_TYPE_ELEMENTARY,
      ESTABLISHMENT_TYPE_SECONDARY: ESTABLISHMENT_TYPE_SECONDARY,
      ESTABLISHMENT_TYPE_COLLEGE: ESTABLISHMENT_TYPE_COLLEGE,
      ESTABLISHMENT_TYPE_UNIVERSITY: ESTABLISHMENT_TYPE_UNIVERSITY,
    };
  },

  computed: {},

  methods: {
    establishmentUpdated() {
      this.$emit("establishmentUpdated", true);
    },
  },

  watch: {
    establishment(establishment) {},
  },
};
</script>
