<template>
  <div v-if="warehouse">
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${warehouse.name}` }} - {{ $t("COMMON.PRODUCTS") }}
      </span>
    </div>

    <warehouse-product-list
      :warehouse="warehouse"
      :filterWarehouse="warehouse.id"
      :filterOrganization="warehouse.organization.id"
    />
  </div>
</template>
<script>
import WarehouseProductList from "@/components/WarehouseProduct/WarehouseProductList.vue";

export default {
  name: "warehouse-view-warehouse-products",

  components: {
    WarehouseProductList,
  },

  props: ["establishment"],

  mixins: [],

  data() {
    return {
      warehouse: null,
    };
  },

  computed: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.establishment?.warehouse?.id;
        if (id) {
          await this.$store.dispatch("warehouses/get", id);
          this.warehouse = this.$store.getters["warehouses/warehouse"];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
