<template>
  <div>
    <div class="row mb-5">
      <div class="col-12" style="display: flex; align-items: center">
        <span class="display-4">
          {{ `${establishment.company_name}` }} -
          {{ $t("COMMON.SALES_ORDERS") }}
        </span>
        <base-button
          type="primary"
          icon
          size="sm"
          @click="
            () => {
              showAddSalesOrderModal = true;
            }
          "
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-copy-04"></i>
            {{ $t("ESTABLISHMENTS.ADD_SALES_ORDER") }}
          </span>
        </base-button>
      </div>
    </div>

    <sales-order-list-table
      :filterCustomer="establishment.id"
      :filterRecipient="{ type: 'customers', id: establishment.customer.id }"
    />

    <estbalishment-view-sales-orders-add-sales-order-modal
      :establishment="establishment"
      :showModal="showAddSalesOrderModal"
      @onCloseModal="
        () => {
          showAddSalesOrderModal = false;
        }
      "
    />
  </div>
</template>

<script>
import SalesOrderListTable from "../../../SalesModule/SalesOrderManagement/partials/SalesOrderListTable.vue";
import EstbalishmentViewSalesOrdersAddSalesOrderModal from "./EstbalishmentViewSalesOrdersAddSalesOrderModal.vue";

export default {
  name: "establishment-view-orders",

  components: {
    SalesOrderListTable,
    EstbalishmentViewSalesOrdersAddSalesOrderModal,
  },

  props: ["establishment"],

  data() {
    return {
      showAddSalesOrderModal: false,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
