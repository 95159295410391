<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${establishment.company_name}` }} -
        {{ $t("COMMON.CAFETERIAS") }}
      </span>

      <div class="col-12 mt-4">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addCafeteria()"
          v-if="$currentUserCan($permissions.PERM_CREATE_CAFETERIAS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("COMMON.ADD_CAFETERIA") }}</span>
        </base-button>
      </div>
    </div>

    <cafeteria-list-table :filterEstablishment="establishment.id" />
  </div>
</template>
<script>
import CafeteriaListTable from "../../CafeteriaManagement/partials/CafeteriaListTable.vue";

export default {
  name: "establishment-view-cafeterias",

  components: { CafeteriaListTable },

  props: ["establishment"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {
    addEstablishmentCafeteras() {
      this.showCafeteriaAddModal = true;
    },

    onCloseModal() {
      this.showCafeteriaAddModal = false;
      this.$emit("establishmentUpdated", true);
      this.rerenderKey++;
    },

    addCafeteria() {
      this.$router.push({
        name: "Add Cafeteria",
        query: {
          establishment_id: this.establishment.id,
          organization_id: this.establishment.organization.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
